<template>
	<div class="home container">
		<metainfo>
			<template v-slot:title="{ content }">{{ content }} - Yay!</template>
		</metainfo>
		<div class="home__wrapper">
			<h1>{{ this.$filters.localize('mainTitle') }}</h1>
			<div class="home__desc">{{ $filters.localize('mainDesc') }}</div>
			<router-link
				class="home__goto"
				to="/booking">
				{{ this.$filters.localize('startBroning') }}
				<div class="home__goto-img"></div>
			</router-link>
		</div>
		<div class="home__bg"></div>
		<div class="home__bg-h"></div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'Home',
	computed: {
		...mapGetters([
			'LOCALE'
		])
	},
	watch: {
		LOCALE()
		{
			document.title = this.$filters.localize('mainpage')
		}
	},
	beforeCreate() {
		setTimeout(() => document.title = this.$filters.localize('mainpage'), 0)
	},
}
</script>

<style lang="scss">
.home
{
	flex: 1 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&.container
	{
		width: 100%;
		padding-top: 95px;
		padding-bottom: 56px;
	}

	@include tablet
	{
		min-height: 820px;
		&.container {padding-bottom: 90px;}
	}

	@include desktop
	{
		min-height: unset;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 0;
		position: relative;

		&.container
		{
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	h1
	{
		text-transform: uppercase;
		@include font-set(24, 1, 700);
		color: #0079C1;
		padding-right: 116px;
		margin-left: -3px;

		@include tablet
		{
			margin-left: -1px;
			font-size: 36px;
			line-height: 1.2;
			padding-right: 0;
			margin-bottom: 19px;
		}

		@include desktop {margin-left: 0px;}
	}

	&__bg
	{
		display: none;

		@include desktop
		{
			flex: 1 0;
			display: block;
			background: url('../assets/img/main-bg.png') center / auto 100% no-repeat;
			max-width: 342px;
			height: 100vh;
			margin-right: 92px;
		}

		@include fullhd
		{
			background-position: center top;
			background-size: auto 100%;
			max-width: 590px;
		}
	}

	&__bg-h
	{
		width: calc(100% + 30px);
		margin-top: 33px;
		margin-left: -15px;
		height: 47.2vw;
		max-height: 300px;
		background: url('../assets/img/main-bg-h.png') center / cover no-repeat;

		@include tablet
		{
			margin-top: 71px;
			height: 45.2vw;
			max-height: 400px;
			background-size: 100% auto;
		}

		@include desktop {display: none;}
	}

	&__wrapper
	{
		@include tablet
		{
			max-width: 445px;
		}

		@include desktop
		{
			transform: translateY(-10%);
		}

		@include fullhd
		{
			transform: translateY(0);
			align-self: flex-start;
			margin-top: 250px;
		}
	}

	&__desc
	{
		@include font-set(14, 1);
		max-width: 250px;

		@include tablet
		{
			font-size: 18px;
			line-height: 1.2em;
			max-width: none;
		}
	}

	&__goto
	{
		display: block;
		@include font-set(12, 50px, 700, .15em);
		max-width: 243px;
		background-color: #EE2A24;
		border-radius: 30px;
		text-align: center;
		text-transform: uppercase;
		padding-right: 57px;
		position: relative;
		margin-top: 31px;
		padding-left: 8px;
		white-space: nowrap;

		&:hover
		{
			background-color: #B32017;

			.home__goto-img {background-color: #EE2A24;}
		}

		@include tablet
		{
			padding-left: 0;
			max-width: 360px;
			font-size: 14px;
			line-height: 60px;
			margin-top: 61px;
		}
	}

	&__goto-img
	{
		width: 46px;
		height: 46px;
		background-color: #B32017;
		border-radius: 50%;
		position: absolute;
		right: 3px;
		top: 50%;
		transform: translateY(-50%);

		&::before
		{
			content: '';
			width: 11px;
			height: 16px;
			background: url('../assets/svg/arrow-empty-r.svg') center / auto no-repeat;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		@include tablet
		{
			width: 56px;
			height: 56px;
		}
	}
}
</style>
